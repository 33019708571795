import React from 'react';
import Banner from './components/banner';
import ContactInfo from './components/contactInfo';

const PresentationCardPage = () => {
  return (
    <main>
      <Banner />
      <ContactInfo />
    </main>
  );
};

export default PresentationCardPage;
