// extracted by mini-css-extract-plugin
export var background_images = "contactInfo-module__background_images___VbSyL";
export var body_background = "#f8f9fa";
export var card = "contactInfo-module__card___N2oJ3";
export var chip = "contactInfo-module__chip___mSphu";
export var contact_info = "contactInfo-module__contact_info___B-C2+";
export var contact_info__container = "contactInfo-module__contact_info__container___nYfnp";
export var contact_info__email = "contactInfo-module__contact_info__email___vMJ0+";
export var container = "contactInfo-module__container___1I3B5";
export var content = "contactInfo-module__content___3t0+C";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "contactInfo-module__full_img___nQymn";
export var full_imgSmall = "contactInfo-module__full_img--small___2+YSW";
export var gray_21 = "contactInfo-module__gray_21___kOAvh";
export var image_container = "contactInfo-module__image_container___KAv3S";
export var image_item = "contactInfo-module__image_item___ja7IB";
export var lg = "1200px";
export var logo = "contactInfo-module__logo___oXaTG";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "contactInfo-module__primary___tJm35";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "contactInfo-module__title___s606B";
export var typography_h1 = "contactInfo-module__typography_h1___J6swD";
export var typography_h2 = "contactInfo-module__typography_h2___Cq7s9";
export var typography_h3 = "contactInfo-module__typography_h3___T6HRb";
export var white = "#fff";
export var white_button = "contactInfo-module__white_button___mXwpj";
export var xl = "1536px";
export var xxl = "2500px";