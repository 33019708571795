import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { injectIntl } from 'react-intl';
import { Grid } from '@mui/material';

import * as styles from './contactInfo.module.scss';
import { Card } from '@components';
import Data from '../../../utils/presentationCard.json';

const ContactInfo = (/*{ intl: { formatMessage } }*/) => {
  return (
    <div className={styles.contact_info}>
      <Grid container spacing={2} className={styles.contact_info__container}>
        <Grid item xs={12}>
          <Card
            image={
              <StaticImage
                src="../../../images/contact_info_1.webp"
                alt="email"
                sizes="(min-width: 54px) 54px, (min-width: 76px) 76px"
              />
            }
            // title={formatMessage({ id: 'contactInfo1Title' })}
            content={
              <a
                className={styles.contact_info__email}
                href={`mailto:${Data.email}`}
              >
                {Data.email}
              </a>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Card
            image={
              <StaticImage
                src="../../../images/contact_info_2.webp"
                alt="Contact info 2"
                sizes="(min-width: 54px) 54px, (min-width: 76px) 76px"
              />
            }
            // title={formatMessage({ id: 'contactInfo2Title' })}
            content={
              <a href={`https://wa.me/${Data.phone_number.replace(/\D/g, '')}`}>
                {Data.phone_number}
              </a>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Card
            image={
              <StaticImage
                src="../../../images/contact_info_3.webp"
                alt="Contact info 3"
                sizes="(min-width: 54px) 54px, (min-width: 76px) 76px"
              />
            }
            // title={formatMessage({ id: 'contactInfo3Title' })}
            content={
              <a href={`tel:+${Data.phone_number.replace(/\D/g, '')}`}>
                {Data.phone_number}
              </a>
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

ContactInfo.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ContactInfo);
